import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { AppFormPages } from "../../../../shared/analytics/analytics.consts";

import { Application } from "../../../application";
import { HelpCallout } from "../../help-callout/help-callout";
import { InfoModal } from "../../info-modal/info-modal";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import significantChangesStyle from "./significant-changes.scss";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";

interface PropTypes extends RouteComponentProps {
  setValue: UseFormTools<Application>["setValue"];
  formData: UseFormTools<Application>["formData"];
}

export const SignificantChanges: React.FC<PropTypes> = ({
  setValue,
  formData,
}) => {
  const [infoModal, setInfoModal] = React.useState(false);
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const modalHeading = "Why are we asking this?";
  const modalDescription = (
    <>
      <p>
        Sometimes life happens and as a responsible lender, we want to make sure
        you&apos;ll be able to make your repayments both short and long-term.
        Things that can change your income or expenses include:
      </p>
      <ul>
        <li>Starting a family</li>
        <li>Changing jobs</li>
        <li>Moving house </li>
        <li>Caring for a loved one </li>
        <li>Changing your relationship status</li>
      </ul>
    </>
  );

  const handleChange = (value: string) => {
    setValue("significantPlannedChanges", value);
    saveProgress();

    if (value === "yes") {
      navigate("/application/type-of-changes");
    } else {
      setValue("significantPlannedChangeTypes", []);
      saveProgress();
      navigate("/application/review-your-details");
    }
  };

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.SIGNIFICANT_CHANGES);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{significantChangesStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              Do you expect changes to your income or expenses that may impact
              your ability to repay the loan?
            </h2>
          </div>
          <HelpCallout
            text="Why are we asking this?"
            setInfoModal={setInfoModal}
          />
        </div>

        <div className="input-wrapper">
          <Field
            type="radio-list"
            label=""
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
            onChange={(e) => handleChange(e.currentTarget.value)}
          />
        </div>
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={modalHeading}
        description={modalDescription}
      />
    </>
  );
};
